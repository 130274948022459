import React, { useState, useEffect } from "react";

const ModeSelector = ({reg_tag, personal, activeMode, setActiveMode}) => {// signalModeChange}) => {
  const [modes, setModes] = useState([]);
  //const [activeMode, setActiveMode] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchModes = () => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user && user.access) {
        const requestData = { reg_tag, personal };

        fetch("/api/get_modes/", {
          method: "POST",
          headers: {
            Authorization: "Bearer " + user.access,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setModes(data.modes);
          if (data.modes.length > 0) {
            // if (activeMode !== data.modes[0]) {
            //   signalModeChange();
            // }
            setActiveMode(data.modes[0]);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching modes:", error);
          setLoading(false);
        });
      }
    };
    fetchModes();

    const intervalId = setInterval(fetchModes, 30000);
    
    return () => clearInterval(intervalId);
  }, [reg_tag, personal, activeMode, setActiveMode]); // signalModeChange]);

  const handleModeChange = (event) => {
    const selectedMode = event.target.value;
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.access) {
        const requestData = { reg_tag, mode_request: selectedMode, personal: personal };

        fetch("/api/set_mode/", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + user.access,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
        })
        .then((response) => {
            if (response.status === 200) {
              // if (activeMode !== selectedMode) {
              //   signalModeChange();
              // }
              setActiveMode(selectedMode);
              
            } else {
              throw new Error("Failed to set mode");
            }
        })
        .catch((error) => {
            console.error("Error setting mode:", error); 
        });
    };
};

  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
        <div className='tooltip'>
          <h3>Mode: </h3>
          {personal ? (
            <div className="tooltiptext">This setting may be configured by your course instructors. If you have a choice, talk to your instructor about what your course modes mean!</div>
          ) : (
            <div className="tooltiptext">Set the course mode for your course. User choice allows students to select from all modes.</div>
          )}
          </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="ModeSelector" 
            style={{display:'flex', gap:'5px', flexDirection:'column', width:'6rem'}}>
            
            <select value={activeMode} 
                style={{
                    fontSize: 'small',
                    padding: '5px',
                    borderRadius: '10px',
                    outline: 'none',
                    textAlign: 'center',
                }}
                onChange={handleModeChange}>
            {modes.map((mode, index) => (
                <option key={index} value={mode}>
                {mode}
                </option>
            ))}
            </select>
        </div>
      )}
    </div>
  );
};

export default ModeSelector;
